import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import IMG1 from '../../assets/meridatours.png'
import IMG2 from '../../assets/graduaal.png'
import IMG3 from '../../assets/baspul.png'
import IMG4 from '../../assets/quince.png'
import IMG5 from '../../assets/landing.jpg'
import IMG6 from '../../assets/yaaxche.png'
import { useTranslation } from 'react-i18next'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import './info.css';

// import required modules
import { Pagination } from 'swiper/modules';

const projects = [
  {
    id:1,
    image:IMG2,
    title:'Gradual',
    link:'https://gra-dual.com/',
    tech:'WordPress',
    type:'Website'
  },
  {
    id:2,
    image:IMG1,
    title:'MeridaTours',
    link:'https://meridatours.com.mx/',
    tech:'WordPress',
    type:'Website'
  },
  {
    id:3,
    image:IMG3,
    title:'Baspúl',
    link:'https://www.casasenmeridabaspul.com/',
    tech:'CodeIgniter 3',
    type:'Website + Admin Panel'
  },
  {
    id:4,
    image:IMG4,
    title:'QuinceQuince',
    link:'https://quincequince.mx/',
    tech:'WordPress',
    type:'Website'
  },
  {
    id:5,
    image:IMG5,
    title:'Santamar',
    link:'https://santamar.blackswan.mx/',
    tech:'WordPress',
    type:'Landing Page'
  },
  {
    id:3,
    image:IMG6,
    title:'Yaaxché',
    link:'https://landing.grupols.mx/',
    tech:'React',
    type:'Landing Page'
  }
]

const Info = () => {
  const [ t, i18n ] = useTranslation("global");
    return (
      <section id="info">
        <h5>{t("portfolio.recentWork")}</h5>
        <h2>{t("portfolio.portfolio")}</h2>
          <Swiper
            slidesPerView={1}
            breakpoints={{
              768:{
                slidesPerView: 2,
              },
              900: {
                slidesPerView: 3,
              },
            }}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            loop={true}
            modules={[Pagination]}
            className="infoSwiper"
          >
          {
          projects.map(({id,image,title,link,tech,type})=>{
            return(
              <SwiperSlide style={{
                backgroundImage:`url(${image})`,
                backgroundSize:'cover',
                backgroundPosition:'center'
              }}>
                <article>
                  <div className='labels'>
                  <small className='techlabel'>{tech}</small>
                  <small className='typelabel'>{type}</small>
                  </div>
                  <div className='overlay'></div>
                  <a href={link}>{t("portfolio.visit")}</a>
                </article>
              </SwiperSlide>
            )
            })
          }
          </Swiper>
      </section>
      );
};

export default Info;
